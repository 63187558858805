import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../img/lilys.svg';
import styles from './Navigation.module.css';

const Navigation = () => {


    return (
        <Navbar expand="lg">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                        <Link to="/" className={styles.navItem}>Home</Link>
                        <Link to="/order-now" className={styles.navItem}>Order now</Link>
                        <Link to="/gallery" className={styles.navItem}>Gallery</Link>
                        <Link to="/faq" className={styles.navItem}>FAQ</Link>
                        <Link to="/contact" className={styles.navItem}>Contact</Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;