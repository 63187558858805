import Header from "../components/Header/Header";
import styles from './Pages.module.css';

const HomePage = () => {


    return (
        <div className={styles.page}>
            <Header />
            <h1>Home</h1>
        </div>
    );
}

export default HomePage;