import Header from "../components/Header/Header";
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import styles from './Pages.module.css';

const ContactPage = () => {


    return (
        <div className={styles.page}>
            <Header />
            <h1>Contact</h1>

            <div className={styles.contactFormContainer}>
                <Container fluid>
                    <Row>
                        <Col lg={{ offset: 2, span: 8 }}>
                            <Form>
                                <Form.Group className={`mb-3 ${styles.contactFormInput}`}>
                                    <Form.Label className={styles.contactFormLabel}>Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>

                                <Form.Group className={`mb-3 ${styles.contactFormInput}`}>
                                    <Form.Label>Telephone number</Form.Label>
                                    <Form.Control type="tel" />
                                </Form.Group>

                                <Form.Group className={`mb-3 ${styles.contactFormInput}`}>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows={5} />
                                </Form.Group>

                                <Button type="submit" className={styles.lilysButton}>
                                    Send message
                                </Button>
                            </Form>
                        </Col>
                    </Row>

                </Container>
            </div>


        </div>
    );
}

export default ContactPage;