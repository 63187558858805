import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';

const lilybakesConfig = {
    apiKey: "AIzaSyCUB6b_IMhK3RbU05syfR_XXn0yC5zIN84",
    authDomain: "lilysbakes.firebaseapp.com",
    projectId: "lilysbakes",
    storageBucket: "lilysbakes.appspot.com",
    messagingSenderId: "376637782354",
    appId: "1:376637782354:web:535314f7313acd6e00a28b",
    measurementId: "G-XD314N6X6L"
}

class Firebase {
    constructor() {
        //app.initializeApp(lilybakesConfig);
        const firebaseApp = firebase.initializeApp(lilybakesConfig);

        this.storage = firebaseApp.storage();
        this.auth = firebaseApp.auth();
        this.db = firebaseApp.database();
    }


    // Auth API

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);


    // Realtime Database API

    products = () => this.db.ref('products');

    product = (uid) => this.db.ref(`products/${uid}`);

    users = () => this.db.ref(`users`);

    user = (uid) => this.db.ref(`users/${uid}`);

}

export default Firebase;